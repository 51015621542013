<template>
    <UPopover mode="click">
        <slot>
            <button
            class="toggle-select-button bg-white w-full flex justify-between focus:ring-wobby-purple-500 focus-within:ring-wobby-purple-500 items-center rounded-md px-2 py-[5px] text-gray-800 text-left ring-gray-300 ring-1 text-base shadow-none"
            :class="[localValue?.length > 0 ? 'ring-wobby-purple-500' : ''].join(' ')">
                <span class="block truncate mr-2">{{ localValue?.length > 0 ? formattedLabel : placeholder }}</span>
                <Icon class="text-gray-400 h-5 w-7" name="heroicons:chevron-down-20-solid"></Icon>
            </button>
        </slot>
        <template #panel>
            <div class="z-20 group min-w-48">
                <div class="relative focus:outline-none scroll-py-1 divide-y divide-gray-200 dark:divide-gray-700 ring-1 ring-gray-200 dark:ring-gray-700 rounded-md shadow-lg bg-white dark:bg-gray-800 p-1">
                    <input v-if="searchable" v-model="searchQuery" ref="searchField" type="text" :placeholder="searchablePlaceholder || 'Search...'" class="w-[calc(100%+0.5rem)] focus:ring-transparent text-sm px-3 py-1.5 dark:text-gray-200 bg-white dark:bg-gray-800 border-0 dark:border-gray-700 focus:border-inherit -mx-1 z-10 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none block truncate text-wobby-purple-500">
                    
                    <div class="overflow-y-auto w-[calc(100%+0.5rem)] max-h-[135px] -mx-1 border-gray-200 border-b" v-if="filteredSelectedItems?.length > 0">
                        <div class="flex items-center justify-between sticky top-0 w-full bg-white/90 text-wobby-purple-500 text-sm font-medium py-1.5 px-3 z-10">
                            <span>Selected ({{ filteredSelectedItems.length }})</span>
                            <span class="text-xs font-light hover:underline cursor-pointer text-gray-500" @click="deselectAll">Clear</span>
                        </div>
                        <div class="p-1" v-for="(item, i) in filteredSelectedItems" :key="`option-${i}`">
                            <div class="popup__group__item group flex items-center justify-between gap-2 w-full px-2 py-1.5 text-sm rounded-md text-wobby-purple-500 cursor-pointer hover:bg-wobby-purple-100" @click="selectOption(item)">
                                <span class="truncate">{{ item[itemText] }}</span>
                                <Icon name="heroicons:minus-circle" class="h-4 w-4 min-w-4" />
                            </div>
                        </div>
                    </div>

                    <div class="minimal-scrollbar overflow-y-auto w-[calc(100%+0.5rem)] max-h-[215px] -mx-1" v-if="filteredAvailableItems?.length > 0">
                        <div class="flex items-center justify-between sticky top-0 w-full bg-white/90 text-sm font-medium py-1.5 px-3 z-10">
                            <span>{{optionGroupTitle}}</span>
                            <span class="text-xs font-light hover:underline cursor-pointer text-gray-500" @click="selectAll" v-if="showSelectAll">Select all</span>
                        </div>
                        <div class="p-1" v-for="(item, i) in filteredAvailableItems" :key="`option-${i}`">
                            <div class="popup__group__item group flex items-center justify-between gap-2 w-full px-2 py-1.5 text-sm rounded-md text-gray-700 cursor-pointer hover:bg-wobby-purple-100" @click="selectOption(item)">
                                <span class="truncate">{{ item[itemText] }}</span>
                                <Icon name="heroicons:plus-circle" class="h-4 w-4 min-w-4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </UPopover>
</template>

<script setup lang="ts">
interface Item {
    [key: string]: any;
}

const props = defineProps({
    modelValue: {
        type: Array as PropType<string[]>,
        required: true
    },
    items: {
        type: Array as PropType<Item[]>,
        required: true
    },
    placeholder: {
        type: String,
        default: "Select a value"
    },
    searchable: {
        type: Boolean,
        required: false
    },
    searchablePlaceholder: {
        type: String,
        required: false
    },
    itemText: {
        type: String,
        default: "text"
    },
    itemValue: {
        type: String,
        default: "value"
    },
    optionGroupTitle: {
        type: String,
        default: "Options"
    },
    showSelectAll: {
        type: Boolean,
        default: true
    }
});

const emit = defineEmits(['update:modelValue']);

const open = ref(false);

const searchField = ref<HTMLElement | null>(null);

const localValue = ref(props.modelValue);
const searchQuery = ref("");
const filteredItems = ref(props.items);

watch(() => props.modelValue, (value) => {
    localValue.value = value;
});

watch(() => searchQuery.value, (value) => {
    if (value) {
        const filtered = props.items.filter((item: any) => item[props.itemText].toLowerCase().includes(value.toLowerCase()));
        filteredItems.value = filtered;
    } else {
        filteredItems.value = props.items;
    }
});

const formattedLabel: ComputedRef<string> = computed(() => {
    return localValue.value.map((value: string) => {
        const item = props.items.find((item: any) => item[props.itemValue] === value);
        return item?.[props.itemText];
    }).join(', ');
});


async function handleOpen() { // TODO
    await nextTick();
    const input = searchField.value;
    if (input) {
        input.focus();
    }
}

const filteredSelectedItems = computed(() => {
    return filteredItems.value.filter((item: any) => localValue.value.includes(item[props.itemValue]));
});

const filteredAvailableItems = computed(() => {
    return filteredItems.value.filter((item: any) => !localValue.value.includes(item[props.itemValue]));
});

function selectOption(option: any) {
    localValue.value = localValue.value.includes(option[props.itemValue]) ? localValue.value.filter((item: any) => item !== option[props.itemValue]) : [...localValue.value, option[props.itemValue]];
    emit('update:modelValue', localValue.value);
}

function deselectAll() {
    localValue.value = [];
    emit('update:modelValue', localValue.value);
}
function selectAll() {
    localValue.value = filteredItems.value.map((item: any) => item[props.itemValue]);
    emit('update:modelValue', localValue.value);
}
</script>

<style scoped>
.popup__group__item svg {
    opacity: 0;
}

.popup__group__item:hover svg {
    opacity: .75;
}
</style>